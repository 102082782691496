<template>
  <ModalAtom>
    <template #content>
      <span>Personen är redan aktiv medlem i annan golfklubb vill du byta hemmaklubb?</span>
    </template>
    <template #buttons>
      <ButtonAtom label="Ja" @click="handleHomeClubChange(true)" />
      <ButtonAtom label="Nej" type="secondary" @click="handleHomeClubChange(false)" />
    </template>
  </ModalAtom>
</template>

<script>
import { mapActions } from 'vuex';
import ButtonAtom from '../../../../widget-common/src/components/atoms/ButtonAtom.vue';
import ModalAtom from '../../../../widget-common/src/components/atoms/ModalAtom.vue';
import types from '../../store/types';
export default {
  components: {
    ButtonAtom,
    ModalAtom,
  },
  methods: {
    ...mapActions({
      handleHomeClubChange: types.actions.HANDLE_HOME_CLUB_CHANGE,
    }),
  },
};
</script>

<style scoped></style>
