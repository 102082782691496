<template>
  <div class="check-atom" v-sgf-theme>
    <label class="check-atom__label" v-if="label">{{ label }}</label>
    <div class="check-atom__wrapper" :class="{ 'check-atom__wrapper--checked': value }">
      <input class="check-atom__input" :class="{ 'check-atom__input--checked': value }" type="checkbox" :checked="value" @input="handleInput" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: Boolean,
  },
  emits: ['click'],
  methods: {
    handleInput(e) {
      this.$emit('click', e.target.checked);
    },
  },
};
</script>

<style scoped>
.sgf-ace .check-atom__label {
  display: block;
  font-family: 'BrixSansBlack';
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 8px;
  margin-top: 8px;
  line-height: 14px;
}
.sgf-ace .check-atom__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 3px;
}
.sgf-ace .check-atom__input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;
  accent-color: var(--ace-c-text-black);
  opacity: 0.8;
}

.sgf-ace .check-atom__input--checked {
  opacity: 1;
}

.sgf-ace .check-atom__wrapper:hover,
.sgf-ace .check-atom__wrapper--checked:focus-within {
  background-color: var(--ace-c-primary-blue);
  box-shadow: 0px 0px 0px 2px var(--ace-c-primary-white), 0px 0px 0px 4px var(--ace-c-text-black);
}
</style>
