import ApiBase from '../../widget-common/src/ApiBase';

class Api extends ApiBase {
  async postVerificationEmail(context, payload) {
    await this._fetch(context, `${context.state.apiRoot}/widget/temporaryRegistrationKey`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async postRegistration(context, isConfirmed, payload) {
    let { selectedYear } = context.state;
    return await this._fetch(context, `${context.state.apiRoot}/widget/RegisterMembership?IsConfirmed=${isConfirmed}&Year=${selectedYear}`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async getMemberships(context, birthDateOrGolfId, clubId) {
    let { selectedYear } = context.state;
    const endpoint =
      `${context.state.apiRoot}/widget/GetMembershipTypes?OrganizationID=${clubId}` +
      (birthDateOrGolfId ? `&BirthDateOrGolfId=${birthDateOrGolfId}` : '') +
      `&Year=${selectedYear}`;

    return await this._fetch(context, endpoint, { method: 'GET' });
  }
}
export default Api;
