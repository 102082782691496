<template>
  <div class="box-atom" v-sgf-theme>
    <div class="box-atom__header ace-clamp" :title="label">{{ label }}</div>
    <div class="box-atom__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
  },
};
</script>

<style scoped>
.sgf-ace .box-atom {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid var(--sgf-c-primary);
}
.sgf-ace .box-atom__header {
  background: var(--sgf-c-primary);
  font-weight: bold;
  font-size: 18px;
  padding: 0 16px;
  line-height: 46px;
  min-height: 46px;
}

.sgf-ace .box-atom__content {
  padding: 12px 16px;
}
</style>
