<template>
  <div class="text-input-atom" v-sgf-theme :title="error">
    <label class="text-input-atom__label" :class="{ 'text-input-atom__label--disabled': disabled }">{{ label }}</label>
    <input
      class="text-input-atom__input"
      :style="inputStyle"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      :class="{ 'text-input-atom__input--error': error, 'text-input-atom__input--disabled': disabled }"
      :value="value"
      @input="onInput"
      v-mask="inputMask"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Number],
    width: String,
    mask: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    uppercase: Boolean,
    placeholder: String,
  },
  emits: ['input'],
  computed: {
    inputStyle() {
      let result = {};
      if (this.width && this.$widgetConfiguration.theme === 'ace') {
        result['width'] = this.width;
        result['box-sizing'] = 'unset';
        result['padding-right'] = '16px';
      }
      return result;
    },
    inputMask() {
      if (this.mask) return this.mask;
      else return '';
    },
  },
  methods: {
    onInput(e) {
      let val = e.target.value;

      if (this.uppercase) {
        val = val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
      }

      if (val !== this.value) {
        this.$emit('input', val);
      }
    },
  },
};
</script>

<style scoped>
.sgf-ace .text-input-atom__label {
  display: block;
  border-left: 2px solid var(--sgf-c-primary);
  margin-bottom: 4px;
  padding-left: 16px;
  line-height: 1.7em;
  font-weight: bold;
  font-size: 0.8em;
}
.sgf-ace .text-input-atom__label--disabled {
  border-color: var(--sgf-c-secondary-gray);
  color: var(--sgf-c-primary-gray);
}
.sgf-ace .text-input-atom__input {
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  line-height: 1.5;
  padding: 0 0 0 16px;
  margin: 0;
  border: 1px solid var(--sgf-c-primary-gray);
  background: var(--sgf-c-secondary-gray);
  box-shadow: 0 0 2 var(--sgf-c-secondary-gray);
  border-radius: 3px;
  color: var(--sgf-c-text);
}
.sgf-ace .text-input-atom__input:active {
  outline: none;
  background: var(--sgf-c-secondary);
  box-shadow: 0 0 0 1px var(--sgf-c-text);
  border: 1px solid var(--sgf-c-text);
}
.sgf-ace .text-input-atom__input:not(:active):focus {
  outline: none;
  background: var(--sgf-c-secondary-gray);
  box-shadow: 0 0 2 var(--sgf-c-secondary);
  border: 1px solid var(--sgf-c-text-blue);
}
.sgf-ace .text-input-atom__input--disabled {
  background: var(--sgf-c-secondary-gray);
  border-color: var(--sgf-c-secondary-gray);
}
.sgf-ace .text-input-atom__input--error {
  background: var(--sgf-c-status-pink);
  border-color: var(--sgf-c-status-red);
}
</style>
