<template>
  <div class="dropdown-atom" v-sgf-theme>
    <label class="dropdown-atom__label">{{ label }}</label>
    <select
      :title="error"
      class="dropdown-atom__select"
      :class="{
        'dropdown-atom__select--error': error,
        'dropdown-atom__select--fit-content': fitContent,
      }"
      :disabled="disabled"
      :value="value"
      v-model="selectedValue"
    >
      <option class="dropdown-atom__select__option" v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Number, Boolean],
    options: Array,
    error: String,
    disabled: Boolean,
    fitContent: Boolean,
  },
  emits: ['input'],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>
.sgf-ace .dropdown-atom__label {
  display: block;
  border-left: 2px solid var(--sgf-c-primary);
  margin-bottom: 4px;
  padding-left: 16px;
  line-height: 1.7em;
  font-weight: bold;
  font-size: 0.8em;
}

.sgf-ace .dropdown-atom__select {
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  line-height: 1.5;
  padding: 0 0 0 16px;
  margin: 0;
  border: 1px solid var(--sgf-c-primary-gray);
  background: var(--sgf-c-secondary-gray);
  box-shadow: 0 0 2 var(--sgf-c-secondary-gray);
  border-radius: 3px;
  color: var(--sgf-c-text);
}

.sgf-ace .dropdown-atom__select--fit-content {
  width: fit-content;
  padding: 0 16px 0 16px;
}

.sgf-ace .dropdown-atom__select--error {
  background: var(--sgf-c-status-pink);
  border-color: var(--sgf-c-status-red);
}

.sgf-ace .dropdown-atom__select:active {
  outline: none;
  background: var(--sgf-c-secondary);
  box-shadow: 0 0 0 1px var(--sgf-c-text);
  border: 1px solid var(--sgf-c-text);
}
.sgf-ace .dropdown-atom__select:active {
  outline: none;
  background: var(--sgf-c-secondary);
  box-shadow: 0 0 0 1px var(--sgf-c-text);
  border: 1px solid var(--sgf-c-text);
}
.sgf-ace .dropdown-atom__select:not(:active):focus {
  outline: none;
  background: var(--sgf-c-secondary-gray);
  box-shadow: 0 0 2 var(--sgf-c-secondary);
  border: 1px solid var(--sgf-c-text-blue);
}
.sgf-ace .dropdown-atom__select--error {
  background: var(--sgf-c-status-pink);
  border-color: var(--sgf-c-status-red);
}
</style>
