<template>
  <div class="footer-view" v-sgf-theme>
    <div class="footer-view__recaptcha__checkbox" :style="{ flexDirection: isMobile ? 'column' : 'row' }">
      <CheckAtom label="Välj denna klubb som hemmaklubb" :value="selectHomeClub" @click="setField({ field: 'selectHomeClub', value: $event })" />
      <VueRecaptcha sitekey="6LcsWLwlAAAAALoqunqqDMrfXicWlXOFCKcvEWt4" :size="'normal'" @verify="onVerify" @expired="onExpire" />
    </div>
    <div class="footer-view__layout">
      <div class="footer-view__layout__validation">
        <ValidationMolecule :validations="filteredValidations" size="normal" />
      </div>
      <ButtonAtom v-if="verificationEmailSent" label="Avbryt" type="secondary" @click="cleanup" />
      <ButtonAtom v-if="verificationEmailSent" label="Skicka om" type="secondary" @click="submitVerification" />
      <ButtonAtom :label="submitLabel" :disabled="!challangePassed || validated" @click="submit" />
    </div>
  </div>
</template>

<script>
import types from '../../store/types';
import { mapGetters, mapActions, mapState } from 'vuex';
import ValidationMolecule from '../../../../widget-common/src/components/molecules/ValidationMolecule.vue';
import ButtonAtom from '../../../../widget-common/src/components/atoms/ButtonAtom.vue';
import CheckAtom from '../../../../widget-common/src/components/atoms/CheckAtom.vue';
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  components: {
    ValidationMolecule,
    ButtonAtom,
    VueRecaptcha,
    CheckAtom,
  },

  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      challangePassed: false,
    };
  },
  computed: {
    ...mapGetters({
      validations: types.getters.VALIDATION,
    }),
    ...mapState(['hasGolfId', 'verificationEmailSent', 'selectHomeClub', 'validated']),
    submitLabel() {
      if (this.hasGolfId && !this.verificationEmailSent) {
        return 'Skicka verifikationsmail';
      }
      return 'Skicka';
    },
    filteredValidations() {
      let emailValidations = this.validations.filter((x) => x.field.toLocaleLowerCase().indexOf('email') !== -1);
      let otherValidations = this.validations.filter((x) => x.field.toLocaleLowerCase().indexOf('email') === -1);
      if (emailValidations[0]) {
        return [...otherValidations, emailValidations[0]];
      }
      return this.validations;
    },
  },
  methods: {
    ...mapActions({
      submit: types.actions.SUBMIT,
      cleanup: types.actions.CLEANUP,
      submitVerification: types.actions.SUBMIT_VERIFICATION,
      setField: types.actions.SET_FIELD,
    }),
    onVerify(e) {
      this.challangePassed = true;
      this.setField({ field: 'recaptchaToken', value: e });
    },
    onExpire() {
      this.challangePassed = false;
      this.setField({ field: 'recaptchaToken', value: undefined });
    },
  },
};
</script>
<style scoped>
.sgf-ace .footer-view__recaptcha__checkbox {
  display: flex;
  justify-content: space-between;
  padding: 0 0 var(--ace-gutter) var(--ace-gutter);
  gap: var(--ace-gutter);
}
.sgf-ace .footer-view__layout {
  display: flex;
  gap: var(--ace-gutter);
}
.sgf-ace .footer-view__layout__validation {
  flex: 1;
}
</style>
