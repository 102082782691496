export default {
  validateBirthDate: (birthDate) => {
    const year = parseInt(birthDate.slice(0, 4), 10);
    const month = parseInt(birthDate.slice(4, 6), 10);
    const day = parseInt(birthDate.slice(6, 8), 10);

    if (year < 1900) {
      return { isValid: false, message: 'Ogiltigt datum angivet' };
    }

    if (month < 1 || month > 12) {
      return { isValid: false, message: 'Ogiltigt datum angivet' };
    }

    const isValidDay =
      (month === 2 && day >= 1 && day <= 29) ||
      ((month === 4 || month === 6 || month === 9 || month === 11) && day >= 1 && day <= 30) ||
      (day >= 1 && day <= 31);

    if (!isValidDay) {
      return { isValid: false, message: 'Ogiltigt datum angivet' };
    }
    return { isValid: true };
  },
};
