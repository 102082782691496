<template>
  <div class="modal-atom">
    <div class="modal-atom__content">
      <h1 class="modal-atom__title">{{ title }}</h1>
      <slot name="content" />
      <div class="modal-atom__content__footer">
        <div class="modal-atom__content__footer__buttons">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Obs!',
    },
  },
};
</script>

<style scoped>
.sgf-ace .modal-atom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.sgf-ace .modal-atom__content {
  display: flex;
  flex-direction: column;
  border-top: 4px solid var(--ace-c-primary-blue);
  padding: 40px;
  padding-right: 48px;
  background-color: var(--ace-c-primary-white);
  border-radius: 5px;
  max-height: 75%;
  position: relative;
  width: 35%;
}
.sgf-ace .modal-atom__title {
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'BrixSansBlack';
  font-size: 24px;
  line-height: 30px;
}
.sgf-ace .modal-atom__content__footer {
  display: flex;
  flex-direction: row;
}
.sgf-ace .modal-atom__content__footer__buttons {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  gap: 4px;
}
</style>
