<template>
  <div class="information-molecule ace-shadow ace-scroll" v-sgf-theme>
    <div class="information-molecule__icon-container">
      <svg class="information-molecule__icon-container__icon">
        <use xlink:href="#info"></use>
      </svg>
    </div>
    <div class="information-molecule__content">
      <p v-if="text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationMolecule',
  props: {
    text: String,
  },
};
</script>

<style scoped>
.sgf-ace .information-molecule {
  display: grid;
  grid-template-columns: min-content auto;
  gap: var(--ace-gutter);
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  border-left: 4px solid var(--ace-c-secondary-orange);
}

.sgf-ace .information-molecule__icon-container {
  padding: 20px 0 20px 20px;
  height: 100%;
  box-sizing: border-box;
}

.sgf-ace .information-molecule__icon-container__icon {
  width: 32px;
  height: 32px;
  color: var(--ace-c-secondary-orange);
  fill: white;
}

.sgf-ace .information-molecule__content {
  display: flex;
  justify-content: flex-start;
}
</style>
