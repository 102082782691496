export default {
  getters: {
    VALIDATION: 'validation',
  },
  actions: {
    INITIALIZE: 'initialize',
    SET_FIELD: 'setField',
    SET_PERSON_FIELD: 'setPersonField',
    SET_MEMBERSHIPS: 'setMemberships',
    SUBMIT: 'submit',
    SUBMIT_NEW_MEMBER: 'submitNewMember',
    SUBMIT_VERIFICATION: 'submitVerification',
    VALIDATE_PERSON_MEMBERSHIP: 'validatePersonMembership',
    HANDLE_HOME_CLUB_CHANGE: 'handleHomeClubChange',
    CLEANUP: 'cleanup',
    SET_ERROR: 'setError',
  },
  mutations: {
    SET_FIELD: 'setField',
    SET_PERSON_FIELD: 'setPersonField',
    CLEANUP: 'cleanup',
    CLEANUP_PERSON: 'cleanupPerson',
  },
};
