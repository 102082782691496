<template>
  <div class="table-container">
    <table class="table-atom" v-sgf-theme>
      <thead>
        <tr>
          <th v-for="col in columns" :key="col.key" :style="'text-align:' + col.align">{{ col.header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in data" :key="rowIndex">
          <td v-for="col in columns" :key="rowIndex + col.key" :style="'text-align:' + col.align">
            <slot :name="col.key" :value="row[col.key]">
              {{ row[col.key] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    data: Array,
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-atom {
  overflow: auto;
}

table {
  width: 100%;
}
table,
thead,
tbody,
tfoot,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}
th,
td {
  width: auto;
}

.sgf-ace tbody tr:nth-child(odd) {
  background: var(--sgf-c-secondary);
}

.sgf-ace td {
  padding: 3px 10px 3px 10px;
  font-size: 16px;
}

.sgf-ace th {
  font-weight: bold;
  font-family: BrixSansBold;
  padding: 3px 10px 3px 10px;
  text-align: left;
  font-size: 14px;
  border-bottom: 2px solid var(--bta-c-background);
}

.sgf-ace::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.sgf-ace::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #dddddd;
  background-clip: content-box;
}
</style>
