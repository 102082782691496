<template>
  <BoxAtom label="Medlemskap">
    <div class="membership-view">
      <DropdownAtom label="Vilket år vill du bli medlem?" :value="selectedYear" :options="yearOptions" fitContent @input="setSelectedYear" />
      <DropdownAtom
        label="Välj en medlemskategori"
        :value="selectedMembership"
        :options="membershipOptions"
        @input="setField({ field: 'selectedMembership', value: $event })"
        :error="errors['selectedMembership']"
      />
      <InformationAtom v-if="isPriceBasedOnAgeCategory" text="Priset som visas är baserat på din ålder om du angett Golf-ID eller personnr" />
      <TableAtom v-if="selectedMembership" :columns="columns" :data="data" />
    </div>
  </BoxAtom>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import types from '../../store/types';
import DropdownAtom from '../../../../widget-common/src/components/atoms/DropdownAtom.vue';
import TableAtom from '../../../../widget-common/src/components/atoms/TableAtom.vue';
import BoxAtom from '../../../../widget-common/src/components/atoms/BoxAtom.vue';
import InformationAtom from '../../../../widget-common/src/components/atoms/InformationAtom.vue';
export default {
  components: {
    DropdownAtom,
    TableAtom,
    BoxAtom,
    InformationAtom,
  },
  computed: {
    ...mapState(['persons', 'memberships', 'selectedMembership', 'selectedYear']),
    ...mapGetters({
      validation: types.getters.VALIDATION,
    }),
    errors() {
      return this.validation
        .filter((x) => x.personType === 'member')
        .reduce((pv, cv) => {
          pv[cv.field] = cv.value;
          return pv;
        }, {});
    },
    columns() {
      return [
        {
          key: 'name',
          header: this.data.length > 1 ? 'Avgifter' : 'Avgift',
        },
        {
          key: 'valid',
          header: 'Giltighetsdatum',
        },
        {
          key: 'price',
          header: 'Belopp',
          align: 'right',
        },
      ];
    },
    data() {
      return this.memberships
        .find((x) => x.id === this.selectedMembership)
        .fees.map((x) => {
          return {
            name: x.name,
            price: x.price + ' kr',
            valid: x.validityString ? x.validityString : x.validFrom.split('T')[0] + ' - ' + x.validTo.split('T')[0],
          };
        });
    },
    membershipOptions() {
      if (!this.memberships) {
        return [];
      }
      let memberships = this.memberships
        .map((x) => {
          return { value: x.id, label: x.name };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label, 'sv');
        });

      return [
        {
          label: 'Välj...',
          value: '',
        },
        ...memberships,
      ];
    },
    yearOptions() {
      const currentYear = moment().year();
      const nextYear = moment().add(1, 'year').year();
      return [
        {
          label: `${currentYear}`,
          value: currentYear,
        },
        {
          label: `${nextYear}`,
          value: nextYear,
        },
      ];
    },
    isPriceBasedOnAgeCategory() {
      return this.memberships?.find((x) => x.id === this.selectedMembership)?.priceBasedOnAgeCategory || false;
    },
  },
  methods: {
    ...mapActions({
      setField: types.actions.SET_FIELD,
      setMemberships: types.actions.SET_MEMBERSHIPS,
    }),
    setSelectedYear(e) {
      this.setField({ field: 'selectedYear', value: e });
      const { golfId, ssn } = this.persons.member;
      const birthDateOrGolfId = golfId || ssn.slice(0, 8);
      this.setMemberships(birthDateOrGolfId);
    },
  },
};
</script>

<style scoped>
.sgf-ace .membership-view {
  display: flex;
  flex-direction: column;
  gap: var(--ace-gutter);
}
</style>
