<template>
  <BoxAtom label="Medlem">
    <div class="person-frame" v-sgf-theme>
      <HasGolfIdView />
      <PersonView v-if="!hasGolfId" :personType="personType" :isMobile="isMobile" />
    </div>
  </BoxAtom>
</template>

<script>
import { mapState } from 'vuex';
import HasGolfIdView from '../views/HasGolfIdView.vue';
import PersonView from '../views/PersonView.vue';
import BoxAtom from '../../../../widget-common/src/components/atoms/BoxAtom.vue';
export default {
  components: {
    HasGolfIdView,
    PersonView,
    BoxAtom,
  },
  props: {
    isMobile: Boolean,
  },
  computed: {
    ...mapState(['persons']),
    personType() {
      return 'member';
    },
    hasGolfId() {
      return this.persons[this.personType].hasGolfId;
    },
  },
};
</script>

<style scoped>
.sgf-ace.person-frame {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
}
</style>
