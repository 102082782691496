import types from './types';
import Api from '../api';
const api = new Api();
import payloadHelper from '../helpers/payloadHelper';
import validationHelper from '../helpers/validationHelper';

export default {
  [types.actions.INITIALIZE]: (context, configuration) => {
    context.commit(types.mutations.SET_FIELD, { field: 'configuration', value: configuration });
    context.commit(types.mutations.SET_FIELD, { field: 'apiRoot', value: process.env.VUE_APP_WIDGET_APIENDPOINT });
    context.dispatch(types.actions.SET_MEMBERSHIPS, undefined);
  },

  [types.actions.SET_MEMBERSHIPS]: async (context, birthDateOrGolfId) => {
    const { configuration } = context.state;
    context.commit(types.mutations.SET_FIELD, { field: 'selectedMembership', value: '' });
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: 'Hämtar medlemskap...' });
    let memberships = await api.getMemberships(context, birthDateOrGolfId, configuration.clubId);
    context.commit(types.mutations.SET_FIELD, { field: 'memberships', value: memberships.filter((x) => x.fees.length > 0) });
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
  },

  [types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(types.mutations.SET_FIELD, fieldValue);
  },

  [types.actions.SET_PERSON_FIELD]: (context, fieldValue) => {
    const { field, value, personType } = fieldValue;
    const isMember = personType == 'member';
    if (field === 'hasGolfId' && value !== context.state.hasGolfId && isMember) {
      context.dispatch(types.actions.CLEANUP);
    }
    if (field === 'ssn' && value.length === 13 && isMember) {
      const birthDate = value.slice(0, 8);
      const validation = validationHelper.validateBirthDate(birthDate);
      if (!validation.isValid) {
        context.dispatch(types.actions.SET_ERROR, validation.message);
        return;
      }
      context.dispatch(types.actions.SET_MEMBERSHIPS, birthDate);
    }
    if (field === 'golfId' && value.length === 10 && isMember) {
      context.dispatch(types.actions.SET_MEMBERSHIPS, value);
    }
    context.commit(types.mutations.SET_PERSON_FIELD, fieldValue);
  },

  [types.actions.SUBMIT]: (context) => {
    context.commit(types.mutations.SET_FIELD, { field: 'hasSubmitted', value: true });
    if (context.getters[types.getters.VALIDATION].length > 0) {
      return;
    }
    if (!context.state.persons.member.hasGolfId) {
      context.dispatch(types.actions.SUBMIT_NEW_MEMBER);
      return;
    }
    if (!context.state.persons.member.verificationEmailSent) {
      context.commit(types.mutations.SET_PERSON_FIELD, { field: 'verificationEmailSent', value: true, personType: 'member' });
      context.dispatch(types.actions.SUBMIT_VERIFICATION);
      return;
    }
    if (!context.state.persons.member.personIsValidated) {
      context.commit(types.mutations.SET_PERSON_FIELD, { field: 'personIsValidated', value: true, personType: 'member' });
      context.dispatch(types.actions.VALIDATE_PERSON_MEMBERSHIP);
      return;
    } else {
      context.dispatch(types.actions.SUBMIT_NEW_MEMBER);
      return;
    }
  },

  [types.actions.VALIDATE_PERSON_MEMBERSHIP]: async (context) => {
    const { selectHomeClub } = context.state;
    let payload = payloadHelper.registrationPayload(context.state);
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: 'Validerar...' });
    try {
      let result = await api.postRegistration(context, false, payload);
      if (result && result.hasActiveMembership && selectHomeClub) {
        context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
        context.commit(types.mutations.SET_FIELD, { field: 'validated', value: true });
      } else {
        context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
        context.commit(types.mutations.SET_FIELD, { field: 'registered', value: true });
      }
    } catch {
      context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
      context.commit(types.mutations.SET_PERSON_FIELD, { field: 'personIsValidated', value: false, personType: 'member' });
    }
  },

  [types.actions.SUBMIT_VERIFICATION]: async (context) => {
    let { configuration } = context.state;
    let { golfId, verificationEmail } = context.state.persons.member;
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: 'Skickar verifikationsmail,' });
    try {
      await api.postVerificationEmail(context, {
        golfId: golfId,
        email: verificationEmail,
        organizationId: configuration.clubId,
      });
      context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
    } catch {
      context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
      context.commit(types.mutations.SET_PERSON_FIELD, { field: 'verificationEmailSent', value: false, personType: 'member' });
    }
  },

  [types.actions.SUBMIT_NEW_MEMBER]: async (context) => {
    let payload = payloadHelper.registrationPayload(context.state);
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: 'Registrerar medlemsansökan,' });
    await api.postRegistration(context, true, payload);
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
    context.commit(types.mutations.SET_FIELD, { field: 'registered', value: true });
  },

  [types.actions.HANDLE_HOME_CLUB_CHANGE]: (context, changeHomeClub) => {
    if (!changeHomeClub) {
      context.commit(types.mutations.SET_FIELD, { field: 'selectHomeClub', value: false });
    }
    context.commit(types.mutations.SET_FIELD, { field: 'validated', value: false });
    context.dispatch(types.actions.SUBMIT_NEW_MEMBER);
  },

  [types.actions.CLEANUP]: (context) => {
    context.commit(types.mutations.CLEANUP);
    context.commit(types.mutations.CLEANUP_PERSON, 'member');
    context.commit(types.mutations.CLEANUP_PERSON, 'caregiver1');
    context.commit(types.mutations.CLEANUP_PERSON, 'caregiver2');
  },

  [types.actions.SET_ERROR]: (context, error) => {
    context.commit(types.mutations.SET_FIELD, { field: 'error', value: error });
    context.commit(types.mutations.SET_FIELD, { field: 'loader', value: undefined });
  },
};
